@mixin bg ($size) {
	background-size: #{$size};
	background-position: center center;
	background-repeat: no-repeat;
}


@mixin centering ($dir) {
	position: absolute;

	@if $dir==v {
		top: 50%;
		transform: translateY(-50%);
	}

	@else if $dir==h {
		left: 50%;
		transform: translateX(-50%);
	}

	@else {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin btn_anim {
	transform-origin: center center;
	transition: all ease-out 240ms;

	&:hover {
		transform: scale(1.05);
	}

	&:focus {
		outline: transparent;
		transform: scale(1.05) trahslateY(-5%);
	}

	&:active {
		transform: scale(0.95);
	}
}

@mixin font-face($name, $file, $weight: 400, $style: normal) {
	@font-face {
		font-family: "#{$name}";
		src: url('../fonts/#{$file}.eot');
		src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
		url('../fonts/#{$file}.woff2') format('woff2'),
		url('../fonts/#{$file}.woff') format('woff');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}