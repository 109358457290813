//MEDIA: 1649
@media screen and (max-width: 1649px) {
  .news__item-image {
    height: 480px;
    max-width: 50%;
  }
  .news__item-info {
    max-width: 50%;
    width: 100%;
  }
  .user__image {
    height: 500px;
    bottom: -100px;
    right: 0;
  }
  .contact__image {
    height: 500px;
    bottom: -300px;
    right: 0;
  }
}

//!MEDIA: 1649

//MEDIA: 1249
@media screen and (max-width: 1249px) {
  .main__wrapper-content {
    padding: 45px 30px;
  }
  .topbanner__imagebox {
    height: 480px;
  }
  .topcontent__linebox {
    margin-bottom: 40px;
  }
  .news {
    margin-top: 100px;
  }
  .topbanner {
    margin-bottom: 100px;
  }
  .news__title {
    margin-bottom: 20px;
  }
  .topbanner__imagebox-text {
    font-size: 25px;
    line-height: 40px;
    left: 20px;
    bottom: 20px;
  }
  .topbanner__imagebox-text span {
    font-size: 60px;
    line-height: 80px;
  }
  .news__item {
    margin-bottom: 40px;
    flex-direction: column;
  }
  .news__item + .news__item {
    padding-top: 40px;
  }
  .news__item-image {
    max-width: 80%;
    margin-bottom: 30px;
  }
  .news__item-info {
    margin-left: 0;
    max-width: 100%;
  }
}

//!MEDIA: 1249

//MEDIA: 1199
@media screen and (max-width: 1199px) {
  .filter__topinner {
    display: block;
  }
  .filter__topinner-btns {
    margin-left: auto;
    margin-top: 15px;
  }
  .topcontent__icon {
    display: none;
  }
}

//!MEDIA: 1199

//MEDIA: 991
@media screen and (max-width: 991px) {
  .news__item-image {
    max-width: 100%;
    height: 440px;
  }
  .topbanner__imagebox-text {
    text-shadow: 2px 0px 2px rgba(150, 150, 150, 1);
  }
  .topbanner__imagebox {
    height: 440px;
  }
  .main__wrapper-content {
    max-width: 100%;
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav__main {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200%);
    transition: all 0.3s;
    &.opened {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      z-index: 998;
      padding: 90px 15px 25px 35px;
      box-shadow: 0px 0px 6px 0px rgba(92, 102, 110, 0.4);
      max-width: 350px;
    }
  }
  .nav-burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    position: fixed;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 999;
    background-color: #1aaed8;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0px 0px 6px 0px rgba(92, 102, 110, 0.2);
    span {
      width: 100%;
      display: flex;
      height: 3px;
      background-color: #fff;
      transition: all 0.3s;
    }
    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 5px;
      width: calc(100% - 10px);
      height: 3px;
      background-color: #fff;
      transition: all 0.3s;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 5px;
      width: calc(100% - 10px);
      height: 3px;
      background-color: #fff;
      transition: all 0.3s;
    }
    &.opened {
      background-color: #fff;
      span {
        display: none;
        background-color: #1aaed8;
      }
      &::before {
        transform: rotate(-45deg);
        top: 17px;
        background-color: #1aaed8;
      }
      &::after {
        transform: rotate(45deg);
        bottom: 19px;
        background-color: #1aaed8;
      }
    }
  }
  .main__wrapper-nav {
    position: relative;
  }
  .contact__items-item {
    max-width: 100%;
  }
}

//!MEDIA: 991

//MEDIA: 767
@media screen and (max-width: 767px) {
}

//!MEDIA: 767

//MEDIA: 649
@media screen and (max-width: 649px) {
  .contact__title {
    font-size: 30px;
    line-height: 40px;
  }
  .user__title {
    font-size: 30px;
    line-height: 40px;
  }
  .contact__items {
    margin-top: 40px;
  }
  .user__form-btn {
    height: 35px;
  }
  .user__image {
    height: 300px;
    bottom: -200px;
  }
  .contact__image {
    height: 300px;
    bottom: -300px;
  }
  .user__form {
    max-width: 100%;
  }
  .user {
    margin-top: 50px;
  }
  .contact {
    margin-top: 50px;
  }
  .filter__topinner-btn + .filter__topinner-btn {
    margin-left: 0;
  }
  .filter__topinner-btn {
    margin-left: 0;
    margin-top: 10px;
    width: fit-content;
  }
  .filter__topinner-btns {
    display: block;
  }
  .filter {
    margin-top: 40px;
  }
  .login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login__form-forgotpass {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .login__form-linebox {
    display: block;
  }
  .login__form-reg {
    a {
      display: block;
    }
  }
  .login__form-logo {
    margin: 38px 0;
  }
  .login__form-input {
    & + & {
      margin-top: 40px;
    }
  }
  .invalid-feedback {
    margin-bottom: 15px;
  }

  .topbanner__imagebox {
    height: 340px;
  }
  .news__title {
    font-size: 30px;
    line-height: 40px;
  }
  .news__item-image {
    height: 340px;
  }
  .news__info-title {
    font-size: 25px;
    line-height: 35px;
  }
  .news__info-text {
    font-size: 16px;
  }
  .nav__main.opened {
    max-width: 70%;
  }
  .topcontent__linebox {
    display: block;
  }
  .topcontent__author {
    margin-left: 0;
    margin-top: 5px;
    text-align: right;
  }
}

//!MEDIA: 649

//MEDIA: 349
@media screen and (max-width: 349px) {
  .nav__main.opened {
    max-width: 90%;
  }
  .news__info-subtitle {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .main__wrapper-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .topbanner__imagebox {
    height: 300px;
  }
  .news__item-image {
    height: 300px;
  }
  .topbanner {
    margin-bottom: 70px;
  }
  .news {
    margin-top: 70px;
  }
  .news__info-title {
    margin-bottom: 15px;
  }
  .news__info-text {
    font-size: 14px;
    line-height: 18px;
  }
  .filter__pagination {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .filter__pagination-number {
    font-size: 11px;
    line-height: 16px;
  }
}

//!MEDIA: 349
