@font-face {
  font-family: "fontawesome-webfont";
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ionicons";
  src: url("../fonts/ionicons.eot");
  src: url("../fonts/ionicons.eot?#iefix") format("embedded-opentype"), url("../fonts/ionicons.woff2") format("woff2"), url("../fonts/ionicons.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
  min-width: 320px;
}

body.noscroll {
  overflow-y: hidden;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button:focus,
input:focus {
  outline: transparent;
}

h5 {
  font-size: 20px;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/* THIS FILE GENERATES AUTOMATICALY FROM src/components/bem-blocks/ FOLDE IF THE RE ARE SCSS FILES */
.news {
  margin-top: 0;
}

.news__title {
  font: normal normal 600 40px/89px Nunito Sans;
  color: #2c2c2c;
}

.news__item {
  display: flex;
  margin-bottom: 80px;
}

.news__item + .news__item {
  border-top: 1px solid #2c2c2c;
  padding-top: 80px;
}

.news__item-image {
  height: 580px;
  width: 100%;
  max-width: 768px;
  object-fit: cover;
}

.news__item-info {
  margin-left: 30px;
}

.news__info-subtitle {
  font: normal normal normal 22px/29px Nunito Sans;
  color: #1aaed8;
  margin-top: 15px;
  margin-bottom: 21px;
}

.news__info-title {
  margin-bottom: 29px;
  font: normal normal bold 40px/50px Nunito Sans;
  color: #1aaed8;
  display: block;
  transition: all 0.3s;
}

.news__info-title:hover {
  opacity: 0.7;
}

.news__info-text {
  font: normal normal normal 18px/24px Nunito Sans;
  color: #2c2c2c;
}

.login {
  width: 100vw;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login ::-webkit-scrollbar-track {
  background-color: #fff;
}

.login__form {
  width: 100%;
  max-width: 440px;
}

.login__form-logo {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin: 68px 0;
}

.login__form-logo img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.login__form-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #707070;
  height: 27px;
  font: normal normal 600 20px/27px Nunito Sans;
  color: #a3a3a3;
}

.login__form-input::placeholder {
  font: normal normal 600 20px/27px "Nunito Sans";
  color: #a3a3a3;
}

.login__form-input + .login__form-input {
  margin-top: 69px;
}

.login__form-input.is-invalid {
  border-bottom: 1px solid red;
}

.invalid-feedback {
  font: normal normal 400 16px/22px Nunito Sans;
  color: red;
  margin-top: 7px;
  display: block;
  margin-bottom: 20px;
}

.login__form-linebox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 29px;
}

.login__form-checkbox {
  display: none;
}

.login__form-label {
  font: normal normal 600 16px/22px Nunito Sans;
  color: #2c2c2c;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.login__form-label::before {
  content: "";
  height: 19px;
  width: 19px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.login__form-label:after {
  content: "";
  position: absolute;
  height: 19px;
  width: 19px;
  transform: scale(0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #1aaed8;
  background-image: url(../img/true.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.login__form-checkbox:checked + .login__form-label::after {
  transform: scale(1);
}

.login__form-forgotpass {
  margin-left: auto;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #1aaed8;
  transition: all 0.3s;
  cursor: pointer;
}

.login__form-forgotpass:hover {
  opacity: 0.7;
}

.login__form-btn {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: transparent;
  font: normal normal 600 26px/35px Nunito Sans;
  color: #ffffff;
  transition: all 0.3s;
  background-color: #1aaed8;
  padding: 4px 20px;
  height: 43px;
}

.login__form-btn:hover {
  opacity: 0.7;
}

.login__form-reg {
  margin-top: 90px;
  text-align: center;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #2c2c2c;
}

.login__form-reg a {
  color: #1aaed8;
  transition: all 0.3s;
}

.login__form-reg a:hover {
  opacity: 0.7;
}

.main__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.nav-burger {
  display: none;
}

.nav__main {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 250px;
  background: #1aaed8;
  padding: 50px 20px 25px 35px;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.nav__list-item + .nav__list-item {
  margin-top: 15px;
}

.nav__list-item.has-children .nav__list-item {
  margin-top: 10px;
  padding-left: 8px;
}

.nav__list-item.has-children .nav__list-item > .nav__list-link > .fa, .nav__list-item.has-children .nav__list-item svg {
  margin-right: 10px;
}

.nav__list-link {
  font: normal normal 600 18px/27px Nunito Sans;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0.2rem;
}

.nav__list-link svg, .nav__list-link > .fa {
  margin-right: 15px;
  height: 20px;
  max-width: 100%;
  fill: #fff;
  transition: all 0.3s;
}

.nav__list-link:hover, .nav__list-link.active {
  color: #2c2c2c;
}

.nav__list-link:hover svg, .nav__list-link.active svg {
  fill: #2c2c2c;
}

.nav-sidebar .nav__list-item.active > .nav-treeview {
  display: block;
}

.nav-sidebar .nav__list-item.active > .nav__list-link {
  color: #2c2c2c;
}

.nav-sidebar .nav__list-item.active > .nav__list-link svg {
  fill: #2c2c2c;
}

.nav-sidebar .nav-link > p {
  width: 86%;
}

.nav-sidebar .nav-link > p > .right {
  top: 0.4rem;
}

.nav-sidebar .nav-link > p > .right:nth-child(2) {
  right: 0.2rem;
}

.nav-sidebar .menu-open > .nav-treeview {
  display: none;
}

.nav__list_link_text {
  width: 89%;
  display: inline-block;
  word-break: break-word;
}

.nav__list--top.nav-sidebar {
  width: 100%;
}

.nav__bottom {
  margin-top: 40px;
}

.nav__list--bottom {
  margin-top: auto;
}

.nav__bottext {
  margin-top: 39px;
  font: normal normal normal 14px/19px Nunito Sans;
  color: #ffffff;
}

.main__wrapper-content {
  width: 100%;
  max-width: calc(100% - 250px);
  background-color: #fff;
  padding: 45px 50px 50px;
}

.topbanner {
  margin-bottom: 40px;
}

.topcontent__linebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 69px;
}

.topcontent__logo {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.topcontent__logo img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.topcontent__autdor {
  font: 18px/24px Nunito Sans;
  color: #2c2c2c;
  margin-left: 10px;
}

.topcontent__icon {
  margin-left: 70px;
}

.topcontent__icon svg {
  fill: #2c2c2c;
  width: 31px;
  height: 31px;
  vertical-align: unset;
}

.hidden_icon {
  visibility: hidden;
}

.topcontent__author {
  font: normal normal normal 18px/24px Nunito Sans;
  color: #2c2c2c;
  margin-left: 10px;
}

.topbanner__imagebox {
  position: relative;
  height: 660px;
  width: 100%;
}

.topbanner__imagebox-text {
  position: absolute;
  bottom: 30px;
  left: 45px;
  font: normal normal normal 33px/89px Nunito Sans;
  letter-spacing: 0.66px;
  color: #ffffff;
}

.topbanner__imagebox-text span {
  font: normal normal 900 80px/89px Nunito Sans;
  letter-spacing: 2.4px;
  display: block;
  margin-bottom: -15px;
}

.topbanner__imagebox-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.filter {
  margin-top: 90px;
}

.filter__topinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter__topinner-info {
  font: normal normal normal 14px/19px Nunito Sans;
  color: #2c2c2c;
}

.filter__topinner-info span {
  font: normal normal bold 14px/19px Nunito Sans;
}

.filter__topinner-btns {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.filter__topinner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #ffffff;
  height: 30px;
  padding: 4px 10px;
  background-color: var(--bg);
  border-radius: 10px;
}

.filter__topinner-btn:hover {
  opacity: 0.7;
}

.filter__topinner-btn + .filter__topinner-btn {
  margin-left: 20px;
}

.filter__table {
  margin-top: 54px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow-x: auto;
}

.filter__table tbody {
  width: 100%;
}

.filter__table.users__table .filter__content-line {
  margin-top: 0;
  padding: 4px 0;
}

.filter__table.table_with_filter .filter__head-col:not(.operations),
.filter__table.table_with_filter .filter__content-line-col:not(.operations) {
  min-width: 250px;
  max-width: 250px;
}

.filter__table.product_details_table .filter__head-col:not(.operations),
.filter__table.product_details_table .filter__content-line-col:not(.operations) {
  min-width: 300px;
  max-width: 300px;
  display: block;
}

.filter__table .table__checkbox {
  padding-right: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.filter__table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filter__head-col {
  max-width: 177px;
  min-width: 177px;
  width: 100%;
  font: 600 16px/22px Nunito Sans;
  color: #2c2c2c;
}

.filter__head-col + .filter__head-col {
  margin-left: 20px;
}

.filter__head-col img {
  height: 14px;
  width: 16px;
  max-width: 100%;
  margin-right: 10px;
  vertical-align: unset;
}

.filter__head-col a {
  font: 600 16px/22px Nunito Sans;
  color: #2c2c2c;
}

.filter__head-col.operations {
  min-width: unset;
  max-width: unset;
}

.filter__head-col-inputbox {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-content: center;
}

.filter__head-col-inputbox:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/fw-icons/magnifying-glass-solid.svg);
  height: 17px;
  width: 15px;
  cursor: pointer;
  opacity: 0.8;
}

.filter__head-col-input {
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #9e9e9e;
  height: 100%;
  font: normal normal 400 16px/22px Nunito Sans;
  color: #2c2c2c;
  padding-right: 25px;
}

.filter__table-content {
  margin-top: 40px;
  padding-right: 20px;
  width: fit-content;
  display: table;
}

.filter__content-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9e9e9e;
  padding-bottom: 8px;
}

.filter__content-line + .filter__content-line {
  margin-top: 8px;
}

.filter__content-line-col {
  max-width: 177px;
  min-width: 177px;
  width: 100%;
  font: normal normal normal 10px/14px Nunito Sans;
  color: #2c2c2c;
  display: flex;
  align-items: center;
  position: relative;
}

.filter__content-line-col + .filter__content-line-col {
  margin-left: 20px;
}

.filter__content-line-col.actions {
  font-size: 16px;
}

.filter__content-line-col.actions a {
  margin-right: 5px;
}

.filter__content-line-col.operations {
  min-width: unset;
  max-width: unset;
}

.filter__content-line-col.operations .btn {
  margin-right: 5px;
}

.filter__content-line-col-view {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  height: 11px;
  width: 16px;
}

.filter__pagination {
  display: flex;
  align-items: center;
  margin-top: 48px;
}

.filter__pagination-number {
  font: normal normal normal 14px/19px Nunito Sans;
  color: #2c2c2c;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.filter__pagination-number:hover {
  color: #1aaed8;
  font: normal normal 600 14px/19px Nunito Sans;
}

.filter__pagination-number.active {
  color: #1aaed8;
  font: normal normal 600 14px/19px Nunito Sans;
}

.filter__pagination-prev {
  cursor: pointer;
  margin-right: 10px;
}

.filter__pagination-prev svg {
  fill: #2c2c2c;
  height: 10px;
  width: 6px;
}

.filter__pagination-next {
  cursor: pointer;
  margin-left: 10px;
}

.filter__pagination-next svg {
  fill: #2c2c2c;
  height: 10px;
  width: 6px;
}

.user {
  margin-top: 140px;
  margin-bottom: 50px;
  position: relative;
  height: 100%;
}

.user__image {
  position: absolute;
  bottom: -130px;
  right: 100px;
  height: 740px;
  max-width: 100%;
  object-fit: cover;
}

.user__form {
  width: 100%;
  max-width: 570px;
}

.user__form-title {
  margin-bottom: 58px;
  font: normal normal bold 40px/50px Nunito Sans;
  color: #2c2c2c;
}

.user__form-linebox {
  margin-bottom: 35px;
}

.user__form-label {
  font: normal normal 600 10px/14px Nunito Sans;
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
}

.user__form-inputbox {
  width: 100%;
  position: relative;
}

.user__form-inputbox svg {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  pointer-events: none;
}

.user__form-inputbox svg.ck {
  transform: none;
}

.user__form-input {
  background-color: transparent;
  width: 100%;
  font: normal normal 600 20px/27px Nunito Sans;
  color: #2c2c2c;
  border: none;
  border-bottom: 1px solid #707070;
  padding-right: 30px;
}

.user__form-input::placeholder {
  font: normal normal 600 20px/27px Nunito Sans;
  color: #2c2c2c;
}

.user__form-btn {
  font: normal normal 600 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  background: #1aaed8;
  border-radius: 10px;
  padding: 4px 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  box-shadow: none;
}

.user__form-btn:hover {
  opacity: 0.7;
}

.contact {
  margin-bottom: 50px;
  margin-top: 150px;
  position: relative;
  height: 100%;
}

.contact__image {
  position: absolute;
  bottom: -250px;
  right: 250px;
  max-width: 100%;
  object-fit: cover;
  height: 560px;
  z-index: 0;
}

.contact__title {
  font: normal normal bold 40px/50px Nunito Sans;
  color: #2c2c2c;
  width: 100%;
  max-width: 970px;
  z-index: 1;
  position: relative;
}

.contact__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  max-width: 880px;
  z-index: 1;
  position: relative;
}

.contact__items-item {
  display: flex;
  align-items: center;
  max-width: calc(50% - 30px);
  width: 100%;
  position: relative;
  padding-left: 27px;
  transition: all 0.3s;
}

.contact__items-item:hover {
  opacity: 0.7;
}

.contact__item-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__item-icon svg {
  height: 18px;
  fill: #1aaed8;
}

.contact__item-text {
  font: normal normal 600 20px/36px Nunito Sans;
  color: #2c2c2c;
}

.btn-primary {
  background-color: #1aaed8;
  border-color: #1aaed8;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #2b7cb2;
  border-color: #2b7cb2;
}

.hb_products .filter__head-col a {
  font: normal normal 600 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #1AAED8;
  opacity: 1;
  margin-left: -5px;
}

.page-link {
  font: normal normal normal 14px/19px Nunito Sans !important;
  border: none !important;
  color: #2c2c2c !important;
  padding: 0.5rem 0.5rem !important;
}

.page-link:hover {
  color: #2c2c2c !important;
}

.page-item.active .page-link {
  color: #1AAED8 !important;
  background: none !important;
}

.page-item:first-child .page-link {
  font-size: 22px !important;
  color: #4c4c4c !important;
  padding-top: 7px !important;
}

.page-item:last-child .page-link {
  font-size: 22px !important;
  color: #4c4c4c !important;
  padding-top: 7px !important;
}

@media screen and (max-width: 1649px) {
  .news__item-image {
    height: 480px;
    max-width: 50%;
  }
  .news__item-info {
    max-width: 50%;
    width: 100%;
  }
  .user__image {
    height: 500px;
    bottom: -100px;
    right: 0;
  }
  .contact__image {
    height: 500px;
    bottom: -300px;
    right: 0;
  }
}

@media screen and (max-width: 1249px) {
  .main__wrapper-content {
    padding: 45px 30px;
  }
  .topbanner__imagebox {
    height: 480px;
  }
  .topcontent__linebox {
    margin-bottom: 40px;
  }
  .news {
    margin-top: 100px;
  }
  .topbanner {
    margin-bottom: 100px;
  }
  .news__title {
    margin-bottom: 20px;
  }
  .topbanner__imagebox-text {
    font-size: 25px;
    line-height: 40px;
    left: 20px;
    bottom: 20px;
  }
  .topbanner__imagebox-text span {
    font-size: 60px;
    line-height: 80px;
  }
  .news__item {
    margin-bottom: 40px;
    flex-direction: column;
  }
  .news__item + .news__item {
    padding-top: 40px;
  }
  .news__item-image {
    max-width: 80%;
    margin-bottom: 30px;
  }
  .news__item-info {
    margin-left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .filter__topinner {
    display: block;
  }
  .filter__topinner-btns {
    margin-left: auto;
    margin-top: 15px;
  }
  .topcontent__icon {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .news__item-image {
    max-width: 100%;
    height: 440px;
  }
  .topbanner__imagebox-text {
    text-shadow: 2px 0px 2px #969696;
  }
  .topbanner__imagebox {
    height: 440px;
  }
  .main__wrapper-content {
    max-width: 100%;
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav__main {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-200%);
    transition: all 0.3s;
  }
  .nav__main.opened {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    z-index: 998;
    padding: 90px 15px 25px 35px;
    box-shadow: 0px 0px 6px 0px rgba(92, 102, 110, 0.4);
    max-width: 350px;
  }
  .nav-burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    position: fixed;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 999;
    background-color: #1aaed8;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0px 0px 6px 0px rgba(92, 102, 110, 0.2);
  }
  .nav-burger span {
    width: 100%;
    display: flex;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .nav-burger::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 5px;
    width: calc(100% - 10px);
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .nav-burger::after {
    content: "";
    position: absolute;
    bottom: 7px;
    left: 5px;
    width: calc(100% - 10px);
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .nav-burger.opened {
    background-color: #fff;
  }
  .nav-burger.opened span {
    display: none;
    background-color: #1aaed8;
  }
  .nav-burger.opened::before {
    transform: rotate(-45deg);
    top: 17px;
    background-color: #1aaed8;
  }
  .nav-burger.opened::after {
    transform: rotate(45deg);
    bottom: 19px;
    background-color: #1aaed8;
  }
  .main__wrapper-nav {
    position: relative;
  }
  .contact__items-item {
    max-width: 100%;
  }
}

@media screen and (max-width: 649px) {
  .contact__title {
    font-size: 30px;
    line-height: 40px;
  }
  .user__title {
    font-size: 30px;
    line-height: 40px;
  }
  .contact__items {
    margin-top: 40px;
  }
  .user__form-btn {
    height: 35px;
  }
  .user__image {
    height: 300px;
    bottom: -200px;
  }
  .contact__image {
    height: 300px;
    bottom: -300px;
  }
  .user__form {
    max-width: 100%;
  }
  .user {
    margin-top: 50px;
  }
  .contact {
    margin-top: 50px;
  }
  .filter__topinner-btn + .filter__topinner-btn {
    margin-left: 0;
  }
  .filter__topinner-btn {
    margin-left: 0;
    margin-top: 10px;
    width: fit-content;
  }
  .filter__topinner-btns {
    display: block;
  }
  .filter {
    margin-top: 40px;
  }
  .login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login__form-forgotpass {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .login__form-linebox {
    display: block;
  }
  .login__form-reg a {
    display: block;
  }
  .login__form-logo {
    margin: 38px 0;
  }
  .login__form-input + .login__form-input {
    margin-top: 40px;
  }
  .invalid-feedback {
    margin-bottom: 15px;
  }
  .topbanner__imagebox {
    height: 340px;
  }
  .news__title {
    font-size: 30px;
    line-height: 40px;
  }
  .news__item-image {
    height: 340px;
  }
  .news__info-title {
    font-size: 25px;
    line-height: 35px;
  }
  .news__info-text {
    font-size: 16px;
  }
  .nav__main.opened {
    max-width: 70%;
  }
  .topcontent__linebox {
    display: block;
  }
  .topcontent__author {
    margin-left: 0;
    margin-top: 5px;
    text-align: right;
  }
}

@media screen and (max-width: 349px) {
  .nav__main.opened {
    max-width: 90%;
  }
  .news__info-subtitle {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .main__wrapper-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .topbanner__imagebox {
    height: 300px;
  }
  .news__item-image {
    height: 300px;
  }
  .topbanner {
    margin-bottom: 70px;
  }
  .news {
    margin-top: 70px;
  }
  .news__info-title {
    margin-bottom: 15px;
  }
  .news__info-text {
    font-size: 14px;
    line-height: 18px;
  }
  .filter__pagination {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .filter__pagination-number {
    font-size: 11px;
    line-height: 16px;
  }
}
