@import "mixins";
@import "vars";
@import "fonts";
@import "local-fonts";
@import "global";
@import "svg";
@import "bem-blocks";
@import "news";

// font-family: 'Nunito Sans', sans-serif;

//start login
.login {
  width: 100vw;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
}
.login__form {
  width: 100%;
  max-width: 440px;
}
.login__form-logo {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin: 68px 0;
  img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.login__form-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #707070;
  height: 27px;
  font: normal normal 600 20px/27px Nunito Sans;
  color: #a3a3a3;
  &::placeholder {
    font: normal normal 600 20px/27px "Nunito Sans";
    color: #a3a3a3;
  }
  & + & {
    margin-top: 69px;
  }
  &.is-invalid {
    border-bottom: 1px solid red;
  }
}
.invalid-feedback {
  font: normal normal 400 16px/22px Nunito Sans;
  color: red;
  margin-top: 7px;
  display: block;
  margin-bottom: 20px;
}
.login__form-linebox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 29px;
}
.login__form-checkbox {
  display: none;
}
.login__form-label {
  font: normal normal 600 16px/22px Nunito Sans;
  color: #2c2c2c;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    height: 19px;
    width: 19px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  &:after {
    content: "";
    position: absolute;
    height: 19px;
    width: 19px;
    transform: scale(0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background-color: #1aaed8;
    background-image: url(../img/true.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.login__form-checkbox:checked + .login__form-label::after {
  transform: scale(1);
}
.login__form-forgotpass {
  margin-left: auto;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #1aaed8;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.login__form-btn {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: transparent;
  font: normal normal 600 26px/35px Nunito Sans;
  color: #ffffff;
  transition: all 0.3s;
  background-color: #1aaed8;
  padding: 4px 20px;
  height: 43px;
  &:hover {
    opacity: 0.7;
  }
}
.login__form-reg {
  margin-top: 90px;
  text-align: center;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #2c2c2c;
  a {
    color: #1aaed8;
    transition: all 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}
//end login

//start news
.main__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.nav-burger {
  display: none;
}
.nav__main {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 250px;
  background: #1aaed8;
  padding: 50px 20px 25px 35px;
  display: flex;
  //flex-direction: column;
  align-content: space-between;
  flex-wrap: wrap;
}
.nav__list-item {
  & + & {
    margin-top: 15px;
  }
  &.has-children{
    .nav__list-item{
      margin-top: 10px;
      padding-left: 8px;
      > .nav__list-link > .fa, svg {
        margin-right: 10px;
      }
    }
  }
}
.nav__list-link {
  font: normal normal 600 18px/27px Nunito Sans;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0.2rem;
  svg, > .fa {
    margin-right: 15px;
    height: 20px;
    max-width: 100%;
    fill: #fff;
    transition: all 0.3s;
  }
  &:hover, &.active {
    color: #2c2c2c;
    svg {
      fill: #2c2c2c;
    }
  }
}
.nav-sidebar{
  .nav__list-item{
    &.active{
      > .nav-treeview{
        display: block;
      }
      > .nav__list-link{
        color: #2c2c2c;
        svg {
          fill: #2c2c2c;
        }
      }
    }
  }
  .nav-link{
    > p{
      width: 86%;
      > .right{
        top: 0.4rem;
        &:nth-child(2){
          right: 0.2rem;
        }
      }
    }
  }
  .menu-open>.nav-treeview{
    display: none;
  }
}
.nav__list_link_text {
  width: 89%;
  display: inline-block;
  word-break: break-word;
}
.nav__list--top{
  &.nav-sidebar{
    width: 100%;
  }
}
.nav__bottom {
  margin-top: 40px;
}
.nav__list--bottom {
  margin-top: auto;
}
.nav__bottext {
  margin-top: 39px;
  font: normal normal normal 14px/19px Nunito Sans;
  color: #ffffff;
}
.main__wrapper-content {
  width: 100%;
  max-width: calc(100% - 250px);
  background-color: #fff;
  padding: 45px 50px 50px;
}
.topbanner {
  margin-bottom: 40px;
}
.topcontent__linebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 69px;
}
.topcontent__logo {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.topcontent__autdor {
  font: 18px/24px Nunito Sans;
  color: #2c2c2c;
  margin-left: 10px;
}
.topcontent__icon {
  margin-left: 70px;
  svg {
    fill: #2c2c2c;
    width: 31px;
    height: 31px;
    vertical-align: unset;
  }
}
.hidden_icon{
    visibility: hidden;
}
.topcontent__author {
  font: normal normal normal 18px/24px Nunito Sans;
  color: #2c2c2c;
  margin-left: 10px;
}
.topbanner__imagebox {
  position: relative;
  height: 660px;
  width: 100%;
}
.topbanner__imagebox-text {
  position: absolute;
  bottom: 30px;
  left: 45px;
  font: normal normal normal 33px/89px Nunito Sans;
  letter-spacing: 0.66px;
  color: #ffffff;
  span {
    font: normal normal 900 80px/89px Nunito Sans;
    letter-spacing: 2.4px;
    display: block;
    margin-bottom: -15px;
  }
}
.topbanner__imagebox-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//start filter
.filter {
  margin-top: 90px;
}
.filter__topinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter__topinner-info {
  font: normal normal normal 14px/19px Nunito Sans;
  color: #2c2c2c;
  span {
    font: normal normal bold 14px/19px Nunito Sans;
  }
}
.filter__topinner-btns {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.filter__topinner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  font: normal normal 600 16px/22px Nunito Sans;
  color: #ffffff;
  height: 30px;
  padding: 4px 10px;
  background-color: var(--bg);
  border-radius: 10px;
  &:hover {
    opacity: 0.7;
  }
  & + & {
    margin-left: 20px;
  }
}
.filter__table {
  margin-top: 54px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow-x: auto;
  tbody {
    width: 100%;
    //display: block;
  }
    &.users__table{
        .filter__content-line{
            margin-top: 0;
            padding: 4px 0;
        }
    }
    &.table_with_filter{
        .filter__head-col:not(.operations),
        .filter__content-line-col:not(.operations){
            min-width: 250px;
            max-width: 250px;

        }
    }
    &.product_details_table{
        .filter__head-col:not(.operations),
        .filter__content-line-col:not(.operations){
            min-width: 300px;
            max-width: 300px;
            display:block;

        }
    }
    .table__checkbox{
        padding-right: 10px;
    }
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}
.filter__table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.filter__head-col {
  max-width: 177px;
  min-width: 177px;
  width: 100%;
  font: 600 16px/22px Nunito Sans;
  color: #2c2c2c;
  & + & {
    margin-left: 20px;
  }
  img {
    height: 14px;
    width: 16px;
    max-width: 100%;
    margin-right: 10px;
    vertical-align: unset;
  }
  a{
    font: 600 16px/22px Nunito Sans;
    color: #2c2c2c;
  }
  &.operations{
      min-width: unset;
      max-width: unset;
  }
}
.filter__head-col-inputbox {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/fw-icons/magnifying-glass-solid.svg);
    height: 17px;
    width: 15px;
    cursor: pointer;
    opacity: 0.8;
  }
}
.filter__head-col-input {
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #9e9e9e;
  height: 100%;
  font: normal normal 400 16px/22px Nunito Sans;
  color: #2c2c2c;
  padding-right: 25px;
}
.filter__table-content {
  margin-top: 40px;
  padding-right: 20px;
  width: fit-content;
  display: table;
}
.filter__content-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9e9e9e;
  padding-bottom: 8px;
  & + & {
    margin-top: 8px;
  }
}
.filter__content-line-col {
  max-width: 177px;
  min-width: 177px;
  width: 100%;
  font: normal normal normal 10px/14px Nunito Sans;
  color: #2c2c2c;
  display: flex;
  align-items: center;
  position: relative;
  & + & {
    margin-left: 20px;
  }
  &.actions{
      font-size: 16px;
      a{
          margin-right: 5px;
      }
  }
    &.operations{
        min-width: unset;
        max-width: unset;
        .btn{
            margin-right: 5px;
        }
    }
}
.filter__content-line-col-view {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  height: 11px;
  width: 16px;
}
.filter__pagination {
  display: flex;
  align-items: center;
  margin-top: 48px;
}
.filter__pagination-number {
  font: normal normal normal 14px/19px Nunito Sans;
  color: #2c2c2c;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: #1aaed8;
    font: normal normal 600 14px/19px Nunito Sans;
  }
  &.active {
    color: #1aaed8;
    font: normal normal 600 14px/19px Nunito Sans;
  }
}
.filter__pagination-prev {
  cursor: pointer;
  margin-right: 10px;
  svg {
    fill: #2c2c2c;
    height: 10px;
    width: 6px;
  }
}
.filter__pagination-next {
  cursor: pointer;
  margin-left: 10px;
  svg {
    fill: #2c2c2c;
    height: 10px;
    width: 6px;
  }
}
//end filter

//start user
.user {
  margin-top: 140px;
  margin-bottom: 50px;
  position: relative;
  height: 100%;
}
.user__image {
  position: absolute;
  bottom: -130px;
  right: 100px;
  height: 740px;
  max-width: 100%;
  object-fit: cover;
}
.user__form {
  width: 100%;
  max-width: 570px;
}
.user__form-title {
  margin-bottom: 58px;
  font: normal normal bold 40px/50px Nunito Sans;
  color: #2c2c2c;
}
.user__form-linebox {
  margin-bottom: 35px;
}
.user__form-label {
  font: normal normal 600 10px/14px Nunito Sans;
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
}
.user__form-inputbox {
  width: 100%;
  position: relative;
  svg {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
    &.ck{
        transform: none;
    }
  }
}
.user__form-input {
  background-color: transparent;
  width: 100%;
  font: normal normal 600 20px/27px Nunito Sans;
  color: #2c2c2c;
  border: none;
  border-bottom: 1px solid #707070;
  padding-right: 30px;
  &::placeholder {
    font: normal normal 600 20px/27px Nunito Sans;
    color: #2c2c2c;
  }
}
.user__form-btn {
  font: normal normal 600 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  background: #1aaed8;
  border-radius: 10px;
  padding: 4px 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  box-shadow: none;
  &:hover {
    opacity: 0.7;
  }
}
//end user

//start contact
.contact {
  margin-bottom: 50px;
  margin-top: 150px;
  position: relative;
  height: 100%;
}
.contact__image {
  position: absolute;
  bottom: -250px;
  right: 250px;
  max-width: 100%;
  object-fit: cover;
  height: 560px;
  z-index: 0;
}
.contact__title {
  font: normal normal bold 40px/50px Nunito Sans;
  color: #2c2c2c;
  width: 100%;
  max-width: 970px;
  z-index: 1;
  position: relative;
}
.contact__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  max-width: 880px;
  z-index: 1;
  position: relative;
}
.contact__items-item {
  display: flex;
  align-items: center;
  max-width: calc(50% - 30px);
  width: 100%;
  position: relative;
  padding-left: 27px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
.contact__item-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 18px;
    fill: #1aaed8;
  }
}
.contact__item-text {
  font: normal normal 600 20px/36px Nunito Sans;
  color: #2c2c2c;
}
//end contact

.btn-primary{
    background-color: #1aaed8;
    border-color: #1aaed8;
}
.btn-primary:hover,
.btn-primary:focus{
    background-color: #3498db;
    border-color: #3498db;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle{
    background-color: #2b7cb2;
    border-color: #2b7cb2;
}

.hb_products {
	.filter__head-col {
		a {
			font: normal normal 600 16px/22px Nunito Sans;
			letter-spacing: 0px;
			color: #1AAED8;
			opacity: 1;
			margin-left: -5px;
		}
	}
}

.page-link {
	font: normal normal normal 14px/19px Nunito Sans !important;
	border: none !important;
	color: #2c2c2c !important;
	padding: 0.5rem 0.5rem !important;
	&:hover {
		color: #2c2c2c !important;
	}
}
.page-item.active {
	.page-link {
		color: #1AAED8 !important;
		background: none !important;
	}
}
.page-item {
	&:first-child {
		.page-link {
			font-size: 22px !important;
			color: #4c4c4c !important;
			padding-top: 7px !important;
		}
	}
	&:last-child {
		.page-link {
			font-size: 22px !important;
			color: #4c4c4c !important;
			padding-top: 7px !important;
		}
	}
}

@import "media";
