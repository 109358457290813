.news {
    margin-top: 0;
    &__title {
        font: normal normal 600 40px/89px Nunito Sans;
        color: #2c2c2c;
    }
    &__item {
        display: flex;
        //justify-content: space-between;
        margin-bottom: 80px;
        & + & {
            border-top: 1px solid #2c2c2c;
            padding-top: 80px;
        }
    }
    &__item-image {
        height: 580px;
        width: 100%;
        max-width: 768px;
        object-fit: cover;
    }
    &__item-info {
        margin-left: 30px;
    }
    &__info-subtitle {
        font: normal normal normal 22px/29px Nunito Sans;
        color: #1aaed8;
        margin-top: 15px;
        margin-bottom: 21px;
    }
    &__info-title {
        margin-bottom: 29px;
        font: normal normal bold 40px/50px Nunito Sans;
        color: #1aaed8;
        display: block;
        transition: all 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
    &__info-text {
        font: normal normal normal 18px/24px Nunito Sans;
        color: #2c2c2c;
    }
}
//end news
